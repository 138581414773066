var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.loja.configuracaoDaLoja && _vm.loja.configuracaoDaLoja.criarProdutoImportacaoEntrada))?_c('tr',[_c('td',{staticStyle:{"max-width":"15rem"}},[_vm._v(_vm._s(_vm.produto.nota.xProd))]),(_vm.produto.encontrado !== null && !(_vm.produto.encontrado && _vm.produto.encontrado.id) &&
	(_vm.loja.configuracaoDaLoja && _vm.loja.configuracaoDaLoja.criarProdutoImportacaoEntrada))?_c('td',[_c('SeletorDeMarca',{attrs:{"label":"Marca","podeCriar":"","podeEditar":"","return-object":"","clearable":"","disabled":(_vm.produto.encontrado && _vm.produto.encontrado.id),"auto-select-first":"","autocomplete":"off"},on:{"click:clear":function () { return _vm.produto.encontrado ? _vm.produto.encontrado.marca = null : undefined; }},model:{value:(_vm.produto.encontrado.marca),callback:function ($$v) {_vm.$set(_vm.produto.encontrado, "marca", $$v)},expression:"produto.encontrado.marca"}})],1):_c('td',[_vm._v(" "+_vm._s(_vm.produto.encontrado !== null && _vm.produto.encontrado.marca ? _vm.produto.encontrado.marca.nome : "Não definido")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.produto.nota.cProd)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,false,2173627492)},[_c('div',[_vm._v(" NCM: "+_vm._s(_vm.produto.nota.NCM)+" cProd: "+_vm._s(_vm.produto.nota.cProd)+" cEAN: "+_vm._s(_vm.produto.nota.cEAN)+" uCom: "+_vm._s(_vm.produto.nota.uCom)+" qCom: "+_vm._s(_vm.produto.nota.qCom)+" xProd: "+_vm._s(_vm.produto.nota.xProd)+" vUnCom: "+_vm._s(_vm.produto.nota.vUnCom)+" vProd: "+_vm._s(_vm.produto.nota.vProd)+" ")])])],1),_c('td',[_vm._v(" "+_vm._s(_vm.formatarNumero(_vm.produto.nota.qCom))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatarNumero(_vm.produto.nota.vUnCom))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatarNumero(_vm.produto.nota.vProd))+" ")]),_c('td',[_c('v-text-field',{attrs:{"label":"Preço de Venda Varejo","type":"number","step":"0.01","rules":[_vm.obrigatorio]},model:{value:(_vm.produto.precoFinalVarejo),callback:function ($$v) {_vm.$set(_vm.produto, "precoFinalVarejo", _vm._n($$v))},expression:"produto.precoFinalVarejo"}})],1),_c('td',[_c('CampoDePercentual',{attrs:{"label":"Margem de lucro"},model:{value:(_vm.produto.margemDeLucro),callback:function ($$v) {_vm.$set(_vm.produto, "margemDeLucro", $$v)},expression:"produto.margemDeLucro"}})],1),_c('td',[((_vm.produto.encontrado && _vm.produto.encontrado.id) ||
			(_vm.loja.configuracaoDaLoja && !_vm.loja.configuracaoDaLoja.criarProdutoImportacaoEntrada))?_c('BuscaDeProdutoDropdown',{attrs:{"label":"Produto","filtros":_vm.tiposDeProdutos,"return-object":"","requerFilho":"","singleLine":"","rules":[_vm.obrigatorio],"usaLoja":""},model:{value:(_vm.produto.encontrado),callback:function ($$v) {_vm.$set(_vm.produto, "encontrado", $$v)},expression:"produto.encontrado"}}):_c('v-btn',{attrs:{"filled":""},on:{"click":_vm.editarNovoProduto}},[_c('v-icon',[_vm._v(" mdi-pencil ")]),_vm._v(" Editar Produto ")],1)],1)]):_c('tr',[_c('td',{staticStyle:{"max-width":"15rem"}},[_vm._v(_vm._s(_vm.produto.nota.xProd))]),_c('td',[_vm._v(" "+_vm._s(_vm.produto.nota.cProd)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v(" mdi-dots-horizontal ")])]}}])},[_c('div',[_vm._v(" NCM: "+_vm._s(_vm.produto.nota.NCM)+" cProd: "+_vm._s(_vm.produto.nota.cProd)+" cEAN: "+_vm._s(_vm.produto.nota.cEAN)+" uCom: "+_vm._s(_vm.produto.nota.uCom)+" qCom: "+_vm._s(_vm.produto.nota.qCom)+" xProd: "+_vm._s(_vm.produto.nota.xProd)+" vUnCom: "+_vm._s(_vm.produto.nota.vUnCom)+" vProd: "+_vm._s(_vm.produto.nota.vProd)+" ")])])],1),_c('td',[_vm._v(" "+_vm._s(_vm.formatarNumero(_vm.produto.nota.qCom))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatarNumero(_vm.produto.nota.vUnCom))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatarNumero(_vm.produto.nota.vProd))+" ")]),_c('td',[((_vm.produto.encontrado && _vm.produto.encontrado.id) ||
			(_vm.loja.configuracaoDaLoja && !_vm.loja.configuracaoDaLoja.criarProdutoImportacaoEntrada))?_c('BuscaDeProdutoDropdown',{attrs:{"label":"Produto","filtros":_vm.tiposDeProdutos,"return-object":"","requerFilho":"","singleLine":"","rules":[_vm.obrigatorio],"usaLoja":""},model:{value:(_vm.produto.encontrado),callback:function ($$v) {_vm.$set(_vm.produto, "encontrado", $$v)},expression:"produto.encontrado"}}):_c('v-btn',{attrs:{"filled":""},on:{"click":_vm.editarNovoProduto}},[_c('v-icon',[_vm._v(" mdi-pencil ")]),_vm._v(" Editar Produto ")],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }