













































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FindGeneroUseCase, SaveGeneroUseCase } from '../../usecases'
import { Genero } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { DataOptions } from 'vuetify'
import { Page } from '@/models'
import { Pageable } from '@/models/Pageable'
import axios, { CancelTokenSource } from 'axios'
import { ObserveVisibility } from 'vue-observe-visibility'

@Component({
	model: {
		prop: 'genero',
	},
	directives: {
		ObserveVisibility,
	},
})
export default class SeletorDeGenero extends Vue {
	@Prop({ type: [Object, Array], default: null }) genero!: (string | Genero) | (string | Genero)[] | null
	@Prop({ type: Boolean }) podeCriar!: boolean
	@Prop({ type: Boolean }) podeEditar!: boolean
	@Prop({ type: Boolean }) returnObject!: boolean
	@Prop({ type: Boolean }) multiple!: boolean
	busca = ''
	itemEditando: null | ItemSelecao = null
	itensSelecao: ItemSelecao[] = []
	findGeneroUseCase = new FindGeneroUseCase()
	saveGeneroUseCase = new SaveGeneroUseCase()

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	pagina: Page<Genero> | null = null

	cancelToken: CancelTokenSource | null = null

	buscandoMaisItens = false
	buscandoNovosItens = true

	created() {
		this.buscarNovosItens()
	}

	marcaEmItemAutocompletar(genero: Genero | string): ItemSelecao {
		return typeof genero === 'string'
			? {
				id: '',
				nome: genero,
				tipo: 'selecao',
			}
			: {
				...genero,
				tipo: 'selecao',
			}
	}

	get itens() {
		let itens: ItemAutocompletar[] = this.genero === null
			? []
			: (Array.isArray(this.genero)
				? this.genero.map(this.marcaEmItemAutocompletar)
				: [this.marcaEmItemAutocompletar(this.genero)])

		itens = [
			...itens,
			...this.podeCriar
				? [{
					tipo: 'header',
					header: 'Selecione uma opção ou crie uma',
				} as ItemHeader]
				: [],
			...this.itensSelecao,
		]
		if (this.pagina && !this.pagina.last && !this.buscandoNovosItens)
			itens.push({ tipo: 'buscar-mais' })

		const generos = this.genero
		if (!this.podeCriar || !this.busca || this.buscandoNovosItens) return itens
		if (!generos) return [{ tipo: 'novo' }, ...itens]
		if (Array.isArray(generos)) {
			return generos.some(
				genero => this.busca.toLowerCase() === this.obterNomeGenero(genero).toLowerCase(),
			)
				? itens
				: [{ tipo: 'novo' }, ...itens]
		}
		return this.busca.toLowerCase() === this.obterNomeGenero(generos).toLowerCase()
			? itens
			: [{ tipo: 'novo' }, ...itens]
	}

	obterNomeGenero(genero: Genero | string) {
		return typeof genero === 'string'
			? genero
			: genero.nome
	}

	async selecionaItem(item: ItemSelecao | ItemNovo) {
		if (!item) {
			this.$emit('input', null)
			return
		}

		if (item.tipo === 'novo') {
			await this.criarGenero()
		} else {
			this.$emit('input', item)
		}
	}

	async criarGenero() {
		try {
			if (this.busca.trim() == '') return AlertModule.setError("Não pode ser vazio")
			const generoNovo: Genero = { id: '', nome: this.busca.trim() }

			const generoCriado = await this.saveGeneroUseCase.create(generoNovo)

			const item: ItemSelecao = { ...generoCriado, tipo: 'selecao' }
			this.itensSelecao.push(item)
			this.emitirInput(item)

			return generoCriado
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	emitirInput(item: ItemSelecao) {
		if (!Array.isArray(this.genero)) {
			this.$emit('input', this.returnObject
				? item
				: item.id,
			)
		} else {
			const generos = this.genero || []
			this.$emit('input', [
				...generos,
				item,
			])
		}
	}

	editar(item) {
		this.itemEditando = { ...item }
	}

	async confirmarEdicao() {
		if (!this.itemEditando) {
			return
		}
		if (!this.itemEditando.nome) {
			this.itemEditando = null
			return
		}
		const indice = this.itensSelecao.findIndex(
			item => item.id === this.itemEditando?.id,
		)
		if (indice === -1) return

		this.itensSelecao.splice(indice, 1, this.itemEditando)
		const generoEditado = {
			id: this.itemEditando.id,
			nome: this.itemEditando.nome,
		}
		await this.saveGeneroUseCase.update(generoEditado)
		this.itemEditando = null
	}

	filtrar(item: ItemAutocompletar, queryText, itemText) {
		if (item.tipo === 'header') return false

		const hasValue = val => (val ? val : '')
		const textLower = hasValue(itemText).toString().toLowerCase()
		const queryLower = hasValue(queryText).toString().toLowerCase()

		if (item.tipo === 'novo') {
			return (
				queryLower &&
				!this.itensSelecao.some(({ nome }) => nome.toLowerCase() === queryLower)
			)
		}
		if (item.tipo === 'buscar-mais') return this.pagina && !this.pagina.last
		return textLower.includes(queryLower)
	}

	async buscar(paginavel: Pageable = {}): Promise<Genero[]> {
		try {
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findGeneroUseCase.findAll({
				busca: this.busca || undefined,
				page: paginavel.page || 0,
				size: paginavel.size || 10,
				sort: 'nome',
			}, axiosConfig);
			this.paginacao.page = this.pagina.number
			return this.pagina.content
		} catch (e) {
			if (axios.isCancel(e)) throw e
			AlertModule.setError(e)
			throw e
		}
	}

	async buscarNovosItens() {
		this.buscandoNovosItens = true
		const generos = await this.buscar({
			...this.paginacao,
			page: 0,
		})
		this.itensSelecao = []
		this.inserirGeneros(generos)
		this.buscandoNovosItens = false
	}

	async buscarMaisItens() {
		this.buscandoMaisItens = true
		const generos = await this.buscar({
			...this.paginacao,
			page: this.paginacao.page + 1,
		})
		this.inserirGeneros(generos)
		this.buscandoMaisItens = false
	}

	inserirGeneros(generos: Genero[]) {
		this.itensSelecao = [
			...this.itensSelecao,
			...generos.map<ItemSelecao>(item => ({
				...item,
				tipo: 'selecao',
			})),
		]
	}

	@Watch('busca')
	onChangeBusca = this.buscarNovosItens

	mudouVisibilidade(visivel) {
		if (visivel && !this.buscandoMaisItens) this.buscarMaisItens()
	}
}

export type ItemHeader = {
	tipo: 'header'
	header: string
}

export type ItemSelecao = Genero & {
	tipo: 'selecao'
}

export type ItemNovo = {
	tipo: 'novo'
	text: string
}

export type ItemBuscarMais = {
	tipo: 'buscar-mais'
}

export type ItemAutocompletar = ItemHeader | ItemSelecao | ItemNovo | ItemBuscarMais
